<template>
  <div>
    <p class="mb-3">
      Under standard conditions
      <stemble-latex content="$(25^\circ\text{C and 1 atm})$" />
      which of the following statements is true? Feel free to consult the table of thermodynamic
      properties,
      <a
        href="https://openstax.org/books/chemistry-2e/pages/g-standard-thermodynamic-properties-for-selected-substances"
        rel="noopener noreferrer"
        target="_blank"
        >here</a
      >, to help you answer the question.
    </p>

    <v-form @submit.prevent="submitResponse">
      <v-radio-group v-model="inputs.input1" class="pl-8" :disabled="!allowEditing">
        <v-radio
          v-for="option in options1"
          :key="option.value"
          class="d-inline-block"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.expression" />
          </template>
        </v-radio>
      </v-radio-group>

      <p>b) How could the spontaneity of the reaction be reversed?</p>
      <v-radio-group v-model="inputs.input2" class="pl-8" :disabled="!allowEditing">
        <v-radio
          v-for="option in options2"
          :key="option.value"
          class="d-inline-block"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.expression" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '../displayers/ChemicalLatex';

export default {
  name: 'Question286',
  components: {
    StembleLatex,
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
      },
      options1: [
        {
          expression: '$\\text{Diamond converts to graphite spontaneously}$',
          value: 'diamondToGraphite',
        },
        {
          expression: '$\\text{Graphite converts to diamond spontaneously}$',
          value: 'graphiteToDiamond',
        },
        {expression: '$\\text{None of the above}$', value: 'none'},
      ],
      options2: [
        {
          expression: '$\\text{Increase the temperature}$',
          value: 'increaseT',
        },
        {
          expression: '$\\text{Decrease the temperature}$',
          value: 'decreaseT',
        },
        {
          expression: '$\\text{The spontaneity cannot be changed}$',
          value: 'notPossible',
        },
      ],
    };
  },
};
</script>
